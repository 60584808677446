<script setup>
import { router, usePage } from "@inertiajs/vue3";
import { defineAsyncComponent, ref } from "vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";

function getFlagComponent(countryCode) {
    return defineAsyncComponent(() => import(`./Flags/${countryCode.toUpperCase()}.vue`));
}
const page = usePage();
const user = page.props.auth.user;
const updateLanguage = (locale) => {
    const data = {
        locale,
    };

    router.post(route("update-language"), data);
};
const showDropdown = ref(false);

const availableLanguages = usePage().props.availableLanguages;
</script>

<template>
    <div class="relative h-20 flex items-center justify-center">
        <button
            @click.prevent="showDropdown = !showDropdown"
            class="relative group transition-all duration-300 h-full px-2 hover:bg-black/10 flex gap-2 items-center justify-center"
        >
            <span class="rounded overflow-hidden">
                <component
                    class="w-8 group-hover:brightness-90 transition-all duration-300"
                    :is="getFlagComponent(user ? user.locale : 'NL')"
                />
            </span>
            <ChevronDownIcon
                class="w-5 h-5 transition-all duration-300"
                :class="showDropdown ? 'rotate-180 text-theme-primary' : ''"
            />
        </button>

        <transition>
            <div
                v-if="showDropdown"
                class="absolute rounded-lg shadow-lg w-full h-fit top-full mt-2 left-0 bg-white z-50"
            >
                <ul class="py-2">
                    <li
                        class="group hover:bg-black/10 px-3 py-2 flex justify-center transition-all duration-300"
                        v-for="(language, code) in availableLanguages"
                        :key="code"
                    >
                        <div class="rounded w-fit overflow-hidden">
                            <component
                                @click.prevent="updateLanguage(code)"
                                class="w-12 hover:brightness-90 transition-all duration-300"
                                :is="getFlagComponent(code)"
                            />
                        </div>
                    </li>
                </ul>
                <!--                <div class="p-2 pt-0">-->
                <!--                    <button-->
                <!--                        @click="showDropdown = false"-->
                <!--                        class="flex items-center text-theme-primary justify-center h-5 w-full"-->
                <!--                    >-->
                <!--                        <ChevronUpIcon class="w-5 h-5" />-->
                <!--                    </button>-->
                <!--                </div>-->
            </div>
        </transition>
    </div>
</template>

<style scoped></style>
