<script setup>
import NavButton from "@/Components/Navigation/NavButton.vue";
import NavLink from "@/Components/Navigation/NavLink.vue";
import NavSubLink from "@/Components/Navigation/NavSubLink.vue";
import {
    ArrowRightStartOnRectangleIcon as LogoutIcon,
    DocumentTextIcon,
    HomeIcon,
    NewspaperIcon,
    QueueListIcon,
    UserIcon,
} from "@heroicons/vue/24/outline";
import { usePage } from "@inertiajs/vue3";

const page = usePage();

const unreadArticleCount = page.props.unreadArticleCount;
</script>

<template>
    <template v-if="$page.props.auth.user === null">
        <NavLink :href="route('login')" :active="route().current('login')">
            <UserIcon class="nav-icon" />
            {{ trans.get("navigation.login") }}
        </NavLink>
    </template>
    <template
        v-else-if="
            $page.props.auth.user?.two_factor_confirmed_at ||
            $page.props.twoFactorRequired === false ||
            $page.props.isImpersonated
        "
    >
        <NavLink :href="route('dashboard')" :active="route().current('dashboard')">
            <HomeIcon class="nav-icon" />
            {{ trans.get("navigation.home") }}
        </NavLink>

        <NavLink
            v-if="$page.props.auth.user.view_news"
            :href="route('article.index')"
            :active="route().current('article.*')"
            class="relative"
        >
            <NewspaperIcon class="nav-icon" />
            {{ trans.get("navigation.news") }}
            <span
                v-if="unreadArticleCount > 0"
                class="bg-red-600 text-white text-xs -right-2 top-5 absolute h-5 w-5 flex justify-center items-center rounded-full"
            >
                {{ unreadArticleCount }}
            </span>
        </NavLink>

        <NavLink
            v-if="$page.props.auth.user.view_documents"
            :href="route('documents.index')"
            :active="route().current('documents.*')"
        >
            <DocumentTextIcon class="nav-icon" />
            {{ trans.get("navigation.documents") }}
        </NavLink>

        <NavLink :href="route('customer-numbers.index')" :active="route().current('customer-numbers.*')">
            <QueueListIcon class="nav-icon" />
            {{ trans.get("navigation.customer-numbers") }}
        </NavLink>
        <NavButton :active="route().current('profile.*')">
            <template #menu>
                <UserIcon class="nav-icon" />
                {{ trans.get("navigation.account") }}
            </template>

            <template #dropdown>
                <ul class="divide-y">
                    <NavSubLink :href="route('profile.show')" :active="route().current('profile.*')">
                        <UserIcon class="nav-icon" />
                        {{ trans.get("navigation.profile") }}
                    </NavSubLink>
                    <NavSubLink v-if="$page.props.isImpersonated" :href="route('logout.impersonation')" method="post">
                        <LogoutIcon class="nav-icon" />
                        {{ trans.get("navigation.logout") }}
                    </NavSubLink>
                    <NavSubLink v-else :href="route('logout')" method="post">
                        <LogoutIcon class="nav-icon" />
                        {{ trans.get("navigation.logout") }}
                    </NavSubLink>
                </ul>
            </template>
        </NavButton>
    </template>
    <template v-else>
        <NavLink v-if="$page.props.isImpersonated" :href="route('logout.impersonation')" method="post">
            <LogoutIcon class="nav-icon" />
            {{ trans.get("navigation.logout") }}
        </NavLink>
        <NavLink v-else :href="route('logout')" method="post">
            <LogoutIcon class="nav-icon" />
            {{ trans.get("navigation.logout") }}
        </NavLink>
    </template>
</template>
