<script setup>
import { usePage } from "@inertiajs/vue3";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";
import { ref, useSlots } from "vue";

const props = defineProps({
    active: {
        type: Boolean,
        required: false,
    },
});
const slots = useSlots();

const availableLanguages = usePage().props.availableLanguages;
const currentTenant = usePage().props.currentTenant;

const showDropdown = ref(false);
</script>

<template>
    <li class="lg:h-20 relative">
        <div class="lg:h-20 flex items-center">
            <button
                @click="showDropdown = !showDropdown"
                class="hover:text-theme-primary flex gap-4 lg:gap-2 py-3 px-3 items-center transition-colors duration-300"
                :class="active ? 'text-theme-primary ' : ''"
            >
                <slot name="menu" />
                <ChevronDownIcon
                    class="w-6 h-6 lg:w-4 lg:h-4 transition-transform duration-300"
                    :class="showDropdown ? 'rotate-180' : ''"
                />
            </button>
        </div>

        <transition>
            <div
                v-if="slots.dropdown && showDropdown"
                class="lg:absolute border-t lg:border-0 lg:shadow-lg lg:rounded-lg top-full lg:mt-2 bg-white left-0 lg:-ml-3 lpx-8 lg:px-0 lg:py-0 z-50 w-full min-w-full lg:w-max"
            >
                <slot name="dropdown" />
            </div>
        </transition>
    </li>
</template>

<style scoped></style>
