<script setup>
import LanguageSwitcher from "@/Components/LanguageSwitch/LanguageSwitcher.vue";
import LogoSwitch from "@/Components/Logo's/LogoSwitch.vue";
import NavDesktop from "@/Components/Navigation/NavDesktop.vue";
import NavMobile from "@/Components/Navigation/NavMobile.vue";
import { Head, usePage } from "@inertiajs/vue3";
import { computed, ref, useSlots } from "vue";

const props = defineProps({
    title: String,
});

const slots = useSlots();

const page = usePage();
const colors = ref({
    primary: "",
    primaryLight: "",
    primaryDark: "",
});
function setColors() {
    if (page.props.currentTenant.name === "HorecaPact") {
        // colors.value.primary = "#4CAD3D";
        colors.value.primary = "#1C7BBF";
        colors.value.primaryLight = "#78C66C";
        colors.value.primaryDark = "#35792A";
    }
    if (page.props.currentTenant.name === "Procent") {
        colors.value.primary = "#f17400";
        colors.value.primaryLight = "#F69D4A";
        colors.value.primaryDark = "#C25E00";
    }
}

setColors();

document.documentElement.style.setProperty("--primary-color", colors.value.primary);
document.documentElement.style.setProperty("--primary-light-color", colors.value.primaryLight);
document.documentElement.style.setProperty("--primary-dark-color", colors.value.primaryDark);

const pageTitle = computed(() => {
    return `${props.title} - ${page.props.currentTenant.name}`;
});
</script>

<template>
    <Head :title="pageTitle" />

    <div id="all_content" class="text-gray-700 min-h-screen box-border pt-20">
        <header class="fixed w-full z-40 top-0 left-0 bg-white shadow-lg">
            <div class="h-20 max-w-5xl lg:container mx-auto w-full px-4">
                <div class="h-full flex justify-between items-center">
                    <NavMobile />
                    <LogoSwitch
                        pr-logo="h-10 lg:h-12"
                        hp-logo="h-7 lg:h-10"
                        class="h-20 w-fit py-4 flex items-center"
                    />
                    <div class="flex items-center gap-8 lg:gap-8 xl:gap-16">
                        <NavDesktop />
                        <LanguageSwitcher />
                    </div>
                </div>
            </div>
        </header>

        <slot name="banner"></slot>

        <!-- Page Content -->
        <main class="max-w-5xl lg:container mx-auto py-8 lg:py-16 px-4" :class="slots.banner ? '' : 'mt-16'">
            <slot />
        </main>
    </div>
</template>
