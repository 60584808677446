<script setup>
import { Link, usePage } from "@inertiajs/vue3";

const props = defineProps({
    href: {
        type: String,
        required: true,
    },
    active: {
        type: Boolean,
        required: false,
    },
    method: {
        type: String,
        required: false,
        default: "get",
    },
});

const availableLanguages = usePage().props.availableLanguages;
const currentTenant = usePage().props.currentTenant;
</script>

<template>
    <li class="">
        <Link
            class="hover:text-theme-primary flex gap-4 lg:gap-2 py-3 pl-10 lg:px-6 items-center transition-colors duration-300"
            :href="href"
            :method="method"
            :class="active ? ' text-theme-primary ' : ''"
        >
            <slot />
        </Link>
    </li>
</template>

<style scoped></style>
